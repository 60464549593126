<template>
  <el-dialog
    title="카메라 제어"
    :visible.sync="liveViewDialog"
    @close="cctvModalClose"
    append-to-body
    :closeOnClickModal="false"
    top="5vh"
    class="el-dialog-aside"
    width="1200px"
  >
    <el-container>
      <el-aside width="350px">
        <div class="info-panel">
          <div class="info-panel-wrap">
            <el-table :data="fcltList" size="mini" stripe @row-click="selectData" height="580">
              <el-table-column width="100px" prop="fcltId" label="시설물 ID"></el-table-column>
              <el-table-column prop="fcltName" label="시설물 명칭"></el-table-column>
              <el-table-column prop="fcltTypeName" label="시설물 유형"></el-table-column>
            </el-table>
            <dk-el-pagination :totalDocs="totalDocs" :limit="limit" :pagingProc="pagingProc" ref="pagination"></dk-el-pagination>
          </div>
        </div>
      </el-aside>
      <el-container>
        <!-- <el-main> -->
        <div class="cctv-viewer-wrap">
          <div>
            <dk-hive-webrtc-player
              :server_ip="webRtcInfo.ip"
              :server_port="webRtcInfo.port"
              :video_name="webRtcInfo.videoName"
              ref="dkHiveWebrtcPlayer"
              @onloadWebrtcPlayer="onloadWebrtcPlayer"
              videoHeight="400"
            ></dk-hive-webrtc-player>
          </div>

          <div class="camera-status-monitoring-wrap">
            <ul class="camera-status-monitoring">
              <li>
                <el-button
                  size="mini"
                  type="info"
                  circle
                  @click="ptzLoad()"
                  style="background-color: rgba(150, 150, 150, 0.7); border: none; padding: 5px;"
                >
                  <i class="fas fa-sync-alt"></i>
                </el-button>
              </li>
              <li>
                <span>{{ selectedCctv.fcltName }}</span>
              </li>
              <li>
                pan
                <span>{{ pan }}</span>
              </li>
              <li>
                tilt
                <span>{{ tilt }}</span>
              </li>
              <li>
                zoom
                <span>{{ zoom }}</span>
              </li>
              <li>
                focus
                <span>{{ focus }}</span>
              </li>
            </ul>
          </div>
          <div class="camera-info-wrap">
            <div class="camera-preset-list-wrap">
              <el-table
                ref="singleTable"
                :data="presetInfo"
                highlight-current-row
                @current-change="handleCurrentChange"
                size="mini"
                class="preset-table"
                height="220px"
              >
                <!-- <el-table-column
                                    prop="CCTV_MNGM_NMBR"
                                    label="번호"
                                    align="center"
                                    width="45px">
                                    </el-table-column>

                                    <el-table-column
                                    label="프리셋명"
                                    width="170px"
                                    align="center"
                                    >
                                        <template slot-scope="scope">
                                            <td><el-input placeholder="프리셋번호" v-model="scope.row.PSET_NM" size="mini"></el-input></td>
                                        </template>
                                    </el-table-column>

                                    <el-table-column
                                    prop="PAN_VAL"
                                    label="PAN"
                                    align="center"
                                    width="60px">
                                    </el-table-column>

                                    <el-table-column
                                    prop="TILT_VAL"
                                    label="TILT"
                                    align="center"
                                    width="60px">
                                    </el-table-column>

                                    <el-table-column
                                    prop="ZOOM_VAL"
                                    label="ZOOM"
                                    align="center"
                                    width="60px">    
                                    </el-table-column>

                                    <el-table-column
                                    prop="FOCS_VAL"
                                    label="FOCUS"
                                    align="center"
                                    width="65px">
                                    </el-table-column>
                                    
                                    <el-table-column
                                    label="대표프리셋"
                                    width="80"
                                    align="center"
                                    >
                                    <template slot-scope="scope" >
                                        <td @click="changeDefault()"><el-checkbox :max="1" v-model="scope.row.DFLT_YN"></el-checkbox></td>
                                        </template>
                </el-table-column>-->

                <el-table-column label="번호" width="45px" align="center">
                  <template slot-scope="scope" style="height=100%">{{ scope.row.PSET_NMBR }}</template>
                </el-table-column>

                <el-table-column label="프리셋명" width="200px" align="center">
                  <template slot-scope="scope">
                    <el-input placeholder="프리셋명" v-model="scope.row.PSET_NM" size="mini"></el-input>
                  </template>
                </el-table-column>

                <el-table-column label="PAN" width="50px" align="center">
                  <template slot-scope="scope">{{ scope.row.PAN_VAL }}</template>
                </el-table-column>
                <el-table-column label="TILT" width="50px" align="center">
                  <template slot-scope="scope">{{ scope.row.TILT_VAL }}</template>
                </el-table-column>
                <el-table-column label="ZOOM" width="50px" align="center">
                  <template slot-scope="scope">{{ scope.row.ZOOM_VAL }}</template>
                </el-table-column>
                <el-table-column label="FOCUS" width="50px" align="center">
                  <template slot-scope="scope">{{ scope.row.FOCS_VAL }}</template>
                </el-table-column>
                <el-table-column label="대표프리셋" width="70px" align="center">
                  <template slot-scope="scope">
                    <el-checkbox :max="1" v-model="scope.row.DFLT_YN" @click="changeDefault()"></el-checkbox>
                  </template>
                </el-table-column>
              </el-table>
              <div style="float: right; padding: 5px 0;">
                <el-button size="mini" @click="presetSave()">등록</el-button>
                <el-button size="mini" @click="presetMove()">이동</el-button>
                <el-button size="mini" @click="presetDelete()">삭제</el-button>
                <el-button size="mini" @click="presetDefault()">기본프리셋지정</el-button>
              </div>
            </div>
            <div class="cctv-control-wrap">
              <el-tabs v-model="activeName">
                <el-tab-pane label="PTZ제어" name="first">
                  <table class="ptz-table">
                    <colgroup>
                      <col style="width: 20%;" />
                      <col style="width: 20%;" />
                      <col style="width: 20%;" />
                      <col style="width: 20%;" />
                      <col style="width: 20%;" />
                    </colgroup>
                    <tr>
                      <td
                        @mousedown="downContinusMove(5)"
                        @mouseup="upContinusMove()"
                        @touchstart="downContinusMove(5)"
                        @touchstop="upContinusMove()"
                      >
                        <el-button style="width: 100%;">
                          <i class="fas fa-search-plus"></i>
                        </el-button>
                      </td>
                      <td>&nbsp;</td>
                      <td
                        @mousedown="downContinusMove(1)"
                        @mouseup="upContinusMove()"
                        @touchstart="downContinusMove(1)"
                        @touchstop="upContinusMove()"
                      >
                        <el-button style="width: 100%;">
                          <i class="fas fa-arrow-up"></i>
                        </el-button>
                      </td>
                      <td>&nbsp;</td>
                      <td
                        @mousedown="downContinusMove(7)"
                        @mouseup="upContinusMove()"
                        @touchstart="downContinusMove(7)"
                        @touchstop="upContinusMove()"
                      >
                        <el-button style="width: 100%;">
                          <i class="fas fa-plus-circle"></i>
                        </el-button>
                      </td>
                    </tr>
                    <tr>
                      <td>Zoom</td>
                      <td
                        @mousedown="downContinusMove(2)"
                        @mouseup="upContinusMove()"
                        @touchstart="downContinusMove(2)"
                        @touchstop="upContinusMove()"
                      >
                        <el-button style="width: 100%;">
                          <i class="fas fa-arrow-left"></i>
                        </el-button>
                      </td>
                      <td>
                        <el-input v-model="controlSpeed" :disabled="true"></el-input>
                      </td>
                      <td
                        @mousedown="downContinusMove(3)"
                        @mouseup="upContinusMove()"
                        @touchstart="downContinusMove(3)"
                        @touchstop="upContinusMove()"
                      >
                        <el-button style="width: 100%;">
                          <i class="fas fa-arrow-right"></i>
                        </el-button>
                      </td>
                      <td>Focus</td>
                    </tr>
                    <tr>
                      <td
                        @mousedown="downContinusMove(6)"
                        @mouseup="upContinusMove()"
                        @touchstart="downContinusMove(6)"
                        @touchstop="upContinusMove()"
                      >
                        <el-button style="width: 100%;">
                          <i class="fas fa-search-minus"></i>
                        </el-button>
                      </td>
                      <td>&nbsp;</td>
                      <td
                        @mousedown="downContinusMove(4)"
                        @mouseup="upContinusMove()"
                        @touchstart="downContinusMove(4)"
                        @touchstop="upContinusMove()"
                      >
                        <el-button style="width: 100%;">
                          <i class="fas fa-arrow-down"></i>
                        </el-button>
                      </td>
                      <td>&nbsp;</td>
                      <td
                        @mousedown="downContinusMove(8)"
                        @mouseup="upContinusMove()"
                        @touchstart="downContinusMove(8)"
                        @touchstop="upContinusMove()"
                      >
                        <el-button style="width: 100%;">
                          <i class="fas fa-minus-circle"></i>
                        </el-button>
                      </td>
                    </tr>
                  </table>

                  <div class="control-slider-wrap">
                    <span class="camera-control-speed-label">제어속도</span>
                    <el-slider v-model="controlSpeed" :max="64"></el-slider>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="설정" name="second">
                  <el-button type="primary" @click="cameraReset('하드')">H.W Reset</el-button>
                  <el-button type="primary" @click="cameraReset('소프트')">S.W Reset</el-button>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
        <!-- </el-main>                 -->
      </el-container>
    </el-container>
  </el-dialog>
</template>

<script>
import dkHiveWebRtcPlayer from '@/components/webrtc/dkHiveWebRtcPlayer.vue';
import io from 'socket.io-client';
import dkelpagination from '@/components/dkElPagination.vue';

export default {
  name: 'dk-cctv-viewer',
  components: {
    'dk-hive-webrtc-player': dkHiveWebRtcPlayer,
    'dk-el-pagination': dkelpagination,
  },
  created() {},
  destroyed() {
    this.socket.disconnect();
  },
  props: {
    fcltTypeId: {
      required: true,
    },
  },
  data() {
    const _this = this;
    return {
      socket: null,
      liveViewDialog: false,
      activeName: 'first',
      fcltList: [],
      presetInfo: [],
      parameterInfo: {},
      selectedCctv: {
        fcltName: '',
        fcltId: '',
      },
      webRtcInfo: {
        ip: '',
        port: 0,
        videoName: '',
      },
      controlSpeed: 30,
      pan: 0,
      tilt: 0,
      zoom: 0,
      focus: 0,
      fanOn: '',
      fanOff: '',
      heaterOn: '',
      heaterOff: '',
      gimpoCctvProtocolId: '',
      defaultPreset: '',
      // statusIntervalKey: -1,
      doorStatus: 1,
      heaterStatus: 1,
      panStatus: 1,
      // tcpConnecting: 1,
      // ptzStatus: ""
      limit: 10,
      totalDocs: 0,
    };
  },

  methods: {
    async selectData(row) {
      try {
        this.cctvValueReset();
        if (this.selectedCctv.fcltId === row.fcltId) {
          return;
        }
        this.selectedCctv = row;
        this.cctvActivate();
      } catch (err) {
        console.log(err);
      }
    },
    async cctvActivate() {
      try {
        if (this.$refs.dkHiveWebrtcPlayer) this.$refs.dkHiveWebrtcPlayer.stop();

        if (this.selectedCctv.fcltTypeData.managementNumber) this.gimpoCctvProtocolId = this.selectedCctv.fcltTypeData.managementNumber.value;
        else this.gimpoCctvProtocolId = '';

        this.activeName = 'first';
        this.webRtcInfo.port = this.selectedCctv.fcltTypeData.webRtcPort.value || 0;
        this.webRtcInfo.ip = this.selectedCctv.fcltTypeData.webRtcIp.value || '';
        this.webRtcInfo.videoName = this.selectedCctv.fcltTypeData.webRtcVideoName.value || '';

        // await this.ptzLoad();
        this.$emit('updatePlugin', this.selectedCctv);
        setTimeout(() => {
          this.$refs.dkHiveWebrtcPlayer.play(this.webRtcInfo);
          this.ptzLoad();
          this.presetLoad();
        }, 100);
      } catch (err) {
        console.log(err);
      }
    },
    showDialog(fcltData) {
      let me = this;
      me.getFclts(1);
      this.selectedCctv = fcltData;
      this.cctvActivate();

      this.liveViewDialog = true;
      this.socket = io({
        path: '/cameraapi/dk-socket/camera',
        transport: ['websocket'],
      });
      this.socket.on('status', (response) => {
        if (response.cctvId === parseInt(this.gimpoCctvProtocolId)) {
          this.pan = response.statusData.pan;
          this.tilt = response.statusData.tilt;
          this.zoom = response.statusData.zoom;
          this.focus = response.statusData.focus;
        }
      });

      this.socket.on('echo', (response) => {
        console.log(response);
      });
    },
    onloadWebrtcPlayer() {
      this.selectData(this.selectedCctv);
    },
    cctvModalClose() {
      this.gimpoCctvProtocolId = '';
      this.$refs.dkHiveWebrtcPlayer.stop();
    },
    pagingProc(pageNum) {
      this.getFclts();
    },
    async getFclts(pageNum) {
      let me = this;
      let param = {
        page: pageNum || this.$refs.pagination.getPage(),
        limit: this.limit,
      };
      if (this.keyword !== '' && this.keyword) {
        param.keyword = this.keyword;
      } else {
        param.fcltTypeId = this.fcltTypeId;
      }

      const response = await this.$http.get(`${this.$config.getServerConfig().core.api}/core/api/fclt`, { params: param });
      this.fcltList = response.data.docs;
      this.totalDocs = response.data.totalDocs;

      // this.$store.dispatch('fclt/getFclt', param).then(() => {
      //   me.fcltList = me.$store.getters['fclt/getFclt'];
      //   // me.totalDocs = me.$store.getters['fclt/getPagination'].totalDocs
      // });
    },
    cameraReset(resetVal) {
      var resetValue;
      var cctvId = this.gimpoCctvProtocolId;
      if (resetVal == '소프트') {
        resetValue = 1;
      } else {
        resetValue = 2;
      }
      this.$confirm(resetVal + '리셋하시겠습니까?', 'Warning', {
        confirmButtonText: '삭제',
        cancelButtonText: '취소',
        type: 'warning',
      })
        .then(async () => {
          if (resetValue == 1) {
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/business/cctv/control/swReset`, {
              data: { swValue: this.gimpoCctvProtocolId },
            });
          } else {
            let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/business/cctv/control/hwReset`, {
              data: { hwValue: this.gimpoCctvProtocolId },
            });
          }
          this.$message({
            type: 'success',
            message: '리셋 완료.',
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '리셋 취소.',
          });
        });
    },

    pagingProc(pageNum) {
      this.getFclts();
    },
    handleCurrentChange(val) {
      this.currentRow = val;
      // this.selectedCctv = val;
      console.log(this.selectedCctv);
    },
    async presetSave() {
      let me = this;

      if (this.currentRow.DFLT_YN) {
        this.currentRow.DFLT_YN = 'Y';
      } else {
        this.currentRow.DFLT_YN = 'N';
      }

      let param = {
        cctvId: this.currentRow.CCTV_MNGM_NMBR,
        presetNo: this.currentRow.PSET_NMBR,
        presetName: this.currentRow.PSET_NM,
        DFLT: this.currentRow.DFLT_YN,
        pan: this.pan,
        tilt: this.tilt,
        zoom: this.zoom,
        focus: this.focus,
      };

      await this.$http.post(`${this.$config.getServerConfig().core.api}/business/cctv/control/presetSave`, {
        data: param,
      });
      this.checkPreset(1);
    },
    checkPreset(val) {
      this.presetLoad();
      this.presetInfo;
      if (val == 1) {
        return alert('프리셋 등록 완료');
      } else {
        return alert('프리셋 삭제 완료');
      }
    },
    presetTest() {
      let param = {
        cctvId: '24',
        presetNo: '2',
        fcltId: this.selectedCctv.fcltId,
      };

      this.socket.emit('presetMove', param);
    },

    async presetMove() {
      let me = this;
      let param = {
        // cctvId: this.gimpoCctvProtocolId,
        cctvId: this.selectedCctv.fcltId,
        presetNo: this.currentRow.PSET_NMBR.toString(),
        fcltId: this.selectedCctv.fcltId,
      };
      this.socket.emit('presetMove', param);
    },
    presetDelete() {
      let me = this;
      let param = {
        cctvId: this.currentRow.CCTV_MNGM_NMBR,
        presetNo: this.currentRow.PSET_NMBR,
      };

      this.$confirm(param.presetNo + '번 프리셋을 삭제하시겠습니까?', 'Warning', {
        confirmButtonText: '삭제',
        cancelButtonText: '취소',
        type: 'warning',
      }).then(async () => {
        await this.$http.post(`${this.$config.getServerConfig().core.api}/business/cctv/control/presetDelete`, {
          data: param,
        });
        this.checkPreset(2);
      });
    },
    downContinusMove(val) {
      let me = this;
      let param = {
        fcltId: this.selectedCctv.fcltId,
        cctvId: this.gimpoCctvProtocolId,
        pan: 0,
        tilt: 0,
        zoom: 0,
        focus: 0,
      };

      if (val == 1) {
        param.tilt = 1 * this.controlSpeed;
      } else if (val == 2) {
        param.pan = -1 * this.controlSpeed;
      } else if (val == 3) {
        param.pan = 1 * this.controlSpeed;
      } else if (val == 4) {
        param.tilt = -1 * this.controlSpeed;
      } else if (val == 5) {
        param.zoom = 2;
      } else if (val == 6) {
        param.zoom = -2;
      } else if (val == 7) {
        param.focus = 2;
      } else if (val == 8) {
        param.focus = -2;
      }

      this.socket.emit('moveContinous', param);
    },
    async upContinusMove() {
      //마우스업 했을때 현 pan , tilt , zoom 받아오기
      let statusInfo = {};
      let param = {
        fcltId: this.selectedCctv.fcltId,
        cctvId: this.gimpoCctvProtocolId,
        pan: 0,
        tilt: 0,
        zoom: 0,
        focus: 0,
      };

      this.socket.emit('moveContinous', param);
      await this.ptzLoad();
    },
    async setParameter() {
      let param = {
        cctvId: this.gimpoCctvProtocolId,
        fanOnTemp: this.fanOn,
        fanOffTemp: this.fanOff,
        heaterOnTemp: this.heaterOn,
        heaterOffTemp: this.heaterOff,
      };
      let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/cctv/control/setParameter`, {
        data: param,
      });
      this.parameterLoad();
      alert('파라미터 설정 완료');
    },
    async presetDefault() {
      // this.gimpoCctvProtocolId

      var param = {
        cctvId: this.selectedCctv.CCTV_MNGM_NMBR,
        presetNo: this.selectedCctv.PSET_NMBR,
      };

      let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/business/cctv/control/presetSetDFLT`, {
        data: param,
      });
      alert('대표프리셋 설정완료');
    },

    async presetLoad() {
      // let presetData = {};
      let me = this;

      if (me.presetInfo.length > 0) {
        me.presetInfo = [];
      }
      let response = await this.$http.get(`${this.$config.getServerConfig().core.api}/business/cctv/control/presetLoad`, {
        params: { cctvId: this.gimpoCctvProtocolId },
      });

      if (!response.data) {
        return;
      }

      for (var i = 0; i < response.data.length; i++) {
        if (response.data[i].DFLT_YN == 'N') {
          response.data[i].DFLT_YN = false;
        } else {
          response.data[i].DFLT_YN = true;
          this.defaultPreset = response.data[i];
        }
        me.presetInfo.push(response.data[i]);
      }
    },

    async parameterLoad() {
      let parameterData = {};
      let response = await this.$http.post(`${this.$config.getServerConfig().core.api}/cctv/control/getParameter`, {
        data: { cctvId: this.gimpoCctvProtocolId },
      });
      if (!response.data.body) {
        return;
      }
      parameterData = response.data.body;
      this.fanOn = parameterData.parameterInfo.fanOnTemperature;
      this.fanOff = parameterData.parameterInfo.fanOffTemperature;
      this.heaterOn = parameterData.parameterInfo.heaterOnTemperature;
      this.heaterOff = parameterData.parameterInfo.heaterOffTemperature;
      this.doorStatus = parameterData.status.DoorOpenClose;
      this.heaterStatus = parameterData.status.heaterOnOff;
      this.panStatus = parameterData.status.panOnOff;
    },

    async ptzLoad() {
      let statusInfo = {};
      let statusCctvId = this.selectedCctv.fcltId;
      let param = {
        fcltId: this.selectedCctv.fcltId,
        cctvId: statusCctvId,
        statusSync: 0,
        statusEvent: 1,
      };
      this.socket.emit('getPtz', param);
    },

    changeDefault() {
      if (this.currentRow != '') {
        if (this.defaultPreset.PSET_NMBR == this.currentRow.PSET_NMBR) {
        } else {
          this.defaultPreset.DFLT_YN = false;
          this.currentRow.DFLT_YN = true;
          this.defaultPreset = this.currentRow;
        }
      }
    },
    cctvValueReset() {
      this.pan = 0;
      this.tilt = 0;
      this.zoom = 0;
      this.focus = 0;
      this.fanOn = 0;
      this.fanOff = 0;
      this.heaterOn = 0;
      this.heaterOff = 0;
      // this.tcpConnecting = 0;
    },
    // testWs2() {
    //   this.socket.emit("moveContinous", { fcltId: this.selectedCctv.fcltId });
    // }
  },
};
</script>

<style>
.preset-table th > .cell {
  font-size: 12px;
  padding: 0 !important;
}
</style>

<style scoped>
.detect-data-table img {
  height: auto;
  width: 100%;
}

.detect-canvas {
  border-radius: 3px;
  height: auto;
  width: 100%;
}

.el-table--mini td {
  padding: 3px 0 !important;
}

.el-table--mini td,
.el-table--mini {
  padding: 2px 0 !important;
}

.camera-status-monitoring-wrap {
  background-color: #000;
  height: 40px;
  position: relative;
}

.camera-status-monitoring {
  list-style: none;
  margin: 0;
  position: absolute;
  right: 0;
  top: 10px;
}
.camera-status-monitoring::after {
  clear: both;
  content: '';
  display: block;
}
.camera-status-monitoring li {
  color: #cfcfcf;
  float: left;
  margin: 2px 8px;
}

.camera-status-monitoring li span {
  color: #fff;
  font-size: 20px;
  margin: 0 5px;
}

.camera-info-wrap {
  display: flex;
  flex-direction: row;
  height: 260px;
  padding: 0;
  position: relative;
  width: 100%;
}

.camera-preset-list-wrap {
  flex: 1;
}

.cctv-control-wrap {
  height: 250px;
  width: 300px;
}

.camera-control-speed-label {
  width: 120px;
}

.control-slider-wrap {
  margin: 10px auto 0 auto;
  width: 260px;
}

.cctv-viewer-wrap {
  -webkit-box-flex: 1;
  box-sizing: border-box;
  display: block;
  -ms-flex: 1;
  flex: 1;
  flex-basis: auto;
  -ms-flex-preferred-size: auto;
  padding: 0;
  position: relative;
}

.ptz-table {
  width: 100%;
}

.ptz-table td,
.ptz-table th {
  text-align: center;
}
</style>
